import { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';
import style from './cardsCoins.module.css'
import { myPagination } from '../../constants/mainFunctions';


export default function CardCurrentItemsComponent(props) {

    const [myImgId, setMyImgId] = useState(null)

    const myLoader = ({ src, width, quality }) => {
        return `${src}?w=${width}&q=${quality || 75}`
    }

    const [mActive, setMactive] = useState(1)

    const itemsPerPage = 40

    const { pageNumber, pageArray } = myPagination(props, mActive, itemsPerPage)

    let items = [];

    for (let number = 1; number <= pageNumber; number++) {

        items.push(
            <Pagination.Item key={number} active={number === mActive} onClick={() => setMactive(number)}>
                {number}
            </Pagination.Item>,
        );
    }
    const [message, setMessage] = useState('')

    useEffect(() => {
        if (pageArray.length === 0) {
            setMessage("Comming soon...")
        } else {
            setMessage('')
        }
        return (() => setMessage(''))
    }, [pageArray])
  
    return (<article className='sectionOrArticleB' >
        <Container>
            <Row className='justify-content-center  justify-content-md-start pt-1 pt-md-3 pt-lg-4' >                
                {pageArray?.map((e, i) => {
                    return ((e?.image1 && e?.image2 !== undefined) && (e?.image1 && e?.image2 !== null) ? (<Col xs={12} sm={6} md={4} lg={4} xl={3} key={i} className='mb-3' >
                        <Card style={{ maxWidth: '18rem', margin: '.3rem', height: '100%', marginLeft: 'auto', marginRight: 'auto', zIndex: 1 }} className='h-100 border-0'>

                            <Link href={{
                                pathname: `/${props?.coinLink}/[id]`,
                                query: { id: `${e?.docId}` },
                            }} passHref
                            >
                                <div className={style.container} >
                                    <div className={style.text} >
                                        {myImgId == e.docId ? <Image
                                            src={`${e?.image2}`}
                                            loader={myLoader}
                                            alt={e?.title}
                                            fill
                                            priority={true}
                                            sizes="(max-width: 768px) 100vw,
                                        (max-width: 1200px) 50vw,
                                        33vw"
                                            className='cardImage'
                                            onMouseEnter={() => {
                                                setMyImgId(e.docId)
                                            }}
                                            onMouseLeave={() => setMyImgId(null)}
                                        /> :
                                            <Image
                                                src={`${e?.image1}`}
                                                loader={myLoader}
                                                alt={e?.title}
                                                fill
                                                priority={true}
                                                sizes="(max-width: 768px) 100vw,
                                        (max-width: 1200px) 50vw,
                                        33vw"
                                                className='cardImage'
                                                onMouseEnter={() => {
                                                    setMyImgId(e.docId)
                                                }}
                                                onMouseLeave={() => setMyImgId(null)}
                                            />}
                                    </div>
                                </div>
                                <Card.Body >
                                    <p className={style.cardsDescription}>{e?.title}</p>
                                    <p className={`${style.cardsPrice} bg-warning`}>US <span className={style.price}>{Number(e?.price).toLocaleString("en-US", { style: 'currency', currency: 'USD' })}</span></p>
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>) : null)
                })}
            </Row>
        </Container>
        <Container>
            <Col>
                <h2>{message}</h2>
            </Col>
        </Container>
        {pageNumber === 1 && pageArray.length < itemsPerPage ? null :
            <Container>
                <Row className='justify-content-center'>
                    <Col className='justify-content-center'>
                        <article className='sectionOrArticleT d-flex justify-content-center'>
                            <Pagination size="sm">{items}</Pagination>
                        </article>
                    </Col>
                </Row>
            </Container>}
    </article>
    )
}