import { useEffect, useState, useCallback } from "react"

export const getModeClass=(mode)=>{
    if(mode=== null || mode === 'darkMode'){
        return 'darkModeText'
    }else{
        return 'lightModeText'
    }
}


export const getCoinTabKey=()=>{    
    
    let tabKey
        if (typeof window !== "undefined") {
            const key = window.localStorage.getItem('coinTabKey')
            
            if (key === null || key === 'itemDetails') {
                tabKey ='itemDetails'
                }
             if(key === 'specification'){
                tabKey = 'specification'
                }
            }
    return tabKey
}

export const myPagination=(props , mActive, itemsPerPage)=>{
    const [mItem, setMitem]=useState([])    
    const[pageNumber, setPageNumber]=useState(0)
    const[pageArray, setPageArray]=useState([])
    const[makeRander, setMakeRander]=useState(false)

    const getItems=useCallback(()=>{
        const fn = async()=>{
            try{
                let dd = await JSON.parse(props?.data?.itemsArray)
               // let items1=await dd?.map(e=>e)
                await Promise.all(dd)
               .then(e=>setMitem(e)) 
               .then(()=>getData()) 
               .then(()=>setMakeRander(true))        
               .catch(e=>console.log(e))
            }catch(e){
                setMitem([])
            }            
        }
       fn()
    },[])
        const getData=async()=>{
            if(mItem.length>0){
                const itemsArrLength =mItem?.length
             
               const paginationNumbers = Math.ceil(itemsArrLength/itemsPerPage)
              
               setPageNumber(paginationNumbers)
   
               const endArrayAt = itemsPerPage * mActive
   
               const filterPagination = await mItem?.filter((e,i)=>i >= ((mActive-1)*itemsPerPage) &&  i <= endArrayAt-1)
               //const filterPagination = await mItem?.slice(((mActive-1)*itemsPerPage) , endArrayAt-1)
               await setMakeRander(false)
   
               await Promise.all(filterPagination).then(e=>setPageArray(e)).catch((e)=>console.log(e))   
               }       
        }
   
        useEffect(()=>{
            getItems()
            return()=>getItems()
        },[getItems])

        useEffect(()=>{
        getData()
        return()=>getData()        
        },[makeRander, mActive])

        return{pageNumber, pageArray}
}

export const arrayOfItems =(items, id)=>{

    const[featured, setFeatured]=useState([])
    const mArray = useCallback(()=>{
    const fn =async()=>{
        try{
            const flArr = await items.filter((e)=>e?.id !== Number(id))
           
            await Promise.all(flArr).then(e=>setFeatured(e)).catch((e)=>console.log('zv promise error: '+e))
        }catch(e){
            setFeatured([])
        }        
    }
    fn()
    },[])
    useEffect(()=>{
        mArray()
        //  return()=>mArray()
    },[])
return{featured}
}

export const singleItem=(data)=>{

    const[item, setItem]=useState({})

    const getItem = useCallback(()=>{
        setItem(data)
    },[data])

    useEffect(()=>{
        getItem()
    },[getItem])

    return {item}
}


export const arrayOfFeaturedItems=(props, id)=>{

    const[featured, setFeatured]=useState([])
   
    const arr = useCallback(()=>{
        async function fn(){
            const prom = new Promise(async(resolve, reject)=>{
                try{
                    const myItems = await JSON.parse(props?.itemsArray)
                    const item = await myItems.filter((e) => e?.docId !== Number(id))
                    await setFeatured(item)
                    resolve()                  
                }catch(e){
                    //console.log('farray**e**:'+e)
                    setFeatured([])
                    reject()
                }
            })

            await prom           
            .catch(e=>e)
        }
        fn()
    },[])

    useEffect(()=>{
        arr()
        return()=>arr()
    },[arr])
    
    return{featured}
}


export const getDynamicPageHeadData=(props)=>{
    
    const[description, setDescription]=useState('')
    const[id, setId]=useState(null)
    const[ogImg, setOgImg]=useState('')
    const[myCoin, setMyCoin]=useState([])

    const [render, setRender]=useState(false)

    const pushRender=useCallback(()=>{
        
        const fn = async () => {
           
            try{          
            const prom = new Promise(async(resolve, reject)=>{                
                    try{
                        const myCoin1 = await JSON.parse(props?.singleCoin)                       
                        await setMyCoin(myCoin1[0])
                        await setDescription(myCoin1[0]?.title)
                        await setId(myCoin1[0]?.docId)
                        await setOgImg(myCoin1[0]?.image1) 
                        resolve() 
                    }catch(e){
                        reject()
                    }                
                })        
                    await prom        
                    .catch(e=>console.log(e))
                    }catch(e){                  
                        setMyCoin([]) 
                        setRender(false) 
                    }            
                }

                fn() 

                },[])
        
                useEffect(()=>{
                    pushRender()          
                    return()=>{pushRender()}
                }, [pushRender, render])    
        return {description, id, ogImg, myCoin}
  }